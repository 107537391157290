import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaServer } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title:
      'ドメイン・サーバー管理代行｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'ドメイン・サーバーの契約・更新・運用管理を代行し、安定したサイト運営をサポート。技術的な手間を削減し、セキュリティ対策やトラブル対応もお任せください。',
    pageUrl: 'https://www.involve-in.jp/service/server-management',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ドメイン・サーバー管理代行', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ドメイン・サーバー管理代行"
        subtitle="運用の手間を削減"
        icon={FaServer}
        description="ドメイン・サーバーの契約・更新・運用管理を代行し、安定したサイト運営をサポート。技術的な手間を削減し、セキュリティ対策やトラブル対応もお任せください。"
        seo={{
          title:
            'ドメイン・サーバー管理代行｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'ドメイン・サーバーの契約・更新・運用管理を代行し、安定したサイト運営をサポート。技術的な手間を削減し、セキュリティ対策やトラブル対応もお任せください。',
          pageUrl: 'https://www.involve-in.jp/service/server-management',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'ドメイン・サーバー管理代行',
            url: 'https://www.involve-in.jp/service/server-management',
          },
        ]}
        benefits={[
          {
            title: 'WEBがまったくわからなくても大丈夫',
            description:
              'ドメインやサーバーの管理がわからなくても問題ありません。専門知識不要で、安心してお任せいただけます。',
          },
          {
            title: '安定した運用とセキュリティ対策',
            description:
              'サイトの安定稼働を支え、定期的な更新・バックアップ・セキュリティ対策を実施し、安全な運用を確保。',
          },
          {
            title: '予算に合わせた最適な提案',
            description:
              '必要なサーバースペックやドメイン管理プランを、お客様の予算や運用規模に合わせてご提案。',
          },
        ]}
        flow={[
          {
            title: 'ヒアリング',
            duration: null,
            description:
              'ドメイン・サーバーの現状や運用のご要望をお伺いし、最適なプランを提案。奈良県外のお客様もオンラインミーティングで対応可能。',
          },
          {
            title: '契約・設定',
            duration: '即日〜',
            description:
              '必要なドメイン取得やサーバー契約を代行し、最適な環境を構築。DNS設定やSSL対応も実施。',
          },
          {
            title: '運用・管理（継続）',
            duration: '即日〜',
            description:
              'サーバーの安定稼働を維持し、定期的な更新やバックアップ、セキュリティ対策を実施。トラブル発生時も迅速に対応。',
          },
          {
            title: '公開・サポート',
            duration: null,
            description:
              '必要な調整を行い、本番環境で運用開始。運用後も継続的な管理・サポートを提供。',
          },
        ]}
        preparation={[
          {
            title: '現在のドメイン・サーバーの状況確認',
            description:
              'すでに契約中のドメインやサーバーの管理状況を確認し、必要な移管や設定変更を整理。',
          },
          {
            title: '必要な機能の確認',
            description:
              'SSL対応、メールサーバーの有無、サーバーの容量やパフォーマンス要件など、必要な機能を明確に。',
          },
          {
            title: '更新・契約の方針決定',
            description:
              'ドメイン・サーバーの契約更新や移管のタイミングを確認し、最適な運用スケジュールを策定。',
          },
          {
            title: 'バックアップ・セキュリティ対策の検討',
            description:
              'データの自動バックアップやWAF（Web Application Firewall）設定、アクセス制限などの対策を事前に決定。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥5,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
